<template>
  <div
    class="bigContainer"
    v-if="hasContent"
  >
    <!-- <UspsContentBlock /> -->
    <component
      v-for="(contentblock, idx) in contentBlocks"
      :key="idx"
      :is="COMPONENT_MAP[contentblock._block._name]"
      :content="contentblock"
      class="ContentBlock"
      :class="contentblock._block._name"
    />
  </div>
</template>
<script setup lang="ts">
import HeaderContentBlock from '../components/contentblokken/HeaderContentBlock.vue';
import TekstEenKolomsContentBlock from '../components/contentblokken/TekstEenKolomsContentBlock.vue';
import AfbeeldingContentBlock from './contentblokken/AfbeeldingContentBlock.vue';
import TekstTweeKolomsContentBlock from './contentblokken/TekstTweeKolomsContentBlock.vue';
import TitelContentBlock from './contentblokken/TitelContentBlock.vue';
import TekstEnAfbeeldingContentBlock from './contentblokken/TekstEnAfbeeldingContentBlock.vue';
import VeelgesteldeVragenContentBlock from './contentblokken/VeelgesteldeVragenContentBlock.vue';
import DocumentenContentBlock from './contentblokken/DocumentenContentBlock.vue';
import VideoContentBlock from './contentblokken/VideoContentBlock.vue';
import GallerijContentBlock from './contentblokken/GallerijContentBlock.vue';
import CarouselContentBlock from './contentblokken/CarouselContentBlock.vue';
import EmbedContentBlock from './contentblokken/EmbedContentBlock.vue';
import AccommodatieContentBlock from './contentblokken/AccommodatieContentBlock.vue';
import GerelateerdePaginas from './contentblokken/GerelateerdePaginas.vue';
import AfbeeldingBannerContentBlock from './contentblokken/AfbeeldingBannerContentBlock.vue';
import RecentBekekenContentBlock from './contentblokken/RecentBekekenContentBlock.vue';
import EmbedScriptContentBlock from './contentblokken/EmbedScriptContentBlock.vue';
import QuoteContentBlock from './contentblokken/QuoteContentBlock.vue';
import ZoekEnBoekContentBlock from './contentblokken/ZoekEnBoekContentBlock.vue';
import HeaderTextAndImageContentBlock from '../components/contentblokken/HeaderTextAndImageContentBlock.vue';
import UspsContentBlock from './contentblokken/UspsContentBlock.vue';

import dayjs from 'dayjs';

interface Props {
  data: {
    data: {
      _id: number;
    };
    content: {
      content: [
        {
          _block: {
            _id: Number;
            _name: String;
          };
        }
      ];
    };
    metadata: {
      afbeelding: number;
      canonical: string;
      indexeren: string;
      omschrijving: string;
      titel: string;
    };
  };
}
// TODO: moet metaData op contentblocks container niveau of op een hoger niveau?
const props = defineProps<Props>();

const hasContent = computed(() => !!props.data?.content);
const contentBlocks = computed(() =>
  props.data.content.content.filter(
    (block) => dayjs(block.publicatiedatumTot || new Date()) >= dayjs() && dayjs(block.publicatiedatumVan || new Date()) <= dayjs()
  )
);

const COMPONENT_MAP = {
  Header: HeaderContentBlock,
  'Tekst 1 koloms': TekstEenKolomsContentBlock,
  'Tekst 2 koloms': TekstTweeKolomsContentBlock,
  Titel: TitelContentBlock,
  Afbeelding: AfbeeldingContentBlock,
  'Tekst en afbeelding': TekstEnAfbeeldingContentBlock,
  'Veelgestelde vragen': VeelgesteldeVragenContentBlock,
  Documenten: DocumentenContentBlock,
  Video: VideoContentBlock,
  Galerij: GallerijContentBlock,
  Carrousel: CarouselContentBlock,
  Embed: EmbedContentBlock,
  Accommodaties: AccommodatieContentBlock,
  "Gerelateerde pagina's ": GerelateerdePaginas,
  'Afbeelding - Volledige breedte': AfbeeldingBannerContentBlock,
  'Recent bekeken': RecentBekekenContentBlock,
  'Embed Script': EmbedScriptContentBlock,
  Quote: QuoteContentBlock,
  'Zoek en boek': ZoekEnBoekContentBlock,
  'Header + Tekst en afbeelding': HeaderTextAndImageContentBlock,
  Usps: UspsContentBlock,
};
</script>

<style lang="scss" scoped>
@import '~/styles/variables.sass';

.bigContainer {
  display: flex;
  align-items: center;
  flex-direction: column;
}
.ContentBlock {
  padding: 40px 0;
  width: 100%;
  max-width: $grid-size;

  &:first-child {
    border-top: none;
  }

  &.Header,
  &.Carrousel,
  &.AfbeeldingBannerContentBlock,
  &.HeaderTextAndImageContentBlock {
    max-width: 100%;
    border-bottom: none;
    padding: 0 !important;
  }

  &.UspsContentBlock {
    padding: 0;
  }
}

@media (max-width: 1300px) {
  .ContentBlock {
    padding: 42px 24px;
  }
}

@media (max-width: $screen-xs) {
  .ContentBlock {
    padding: 24px;

    &.UspsContentBlock {
      padding: 0 24px;
    }
  }
}
</style>
